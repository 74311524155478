import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'

import { SocialNetworks } from 'src/features/page-footer/molecules'
import { SocialNetwork } from 'src/features/page-footer/types'
import { breakpoints, useDimensions } from 'src/ui/dimensions'
import { Icons } from 'src/ui/icons'
import { Header, Markdown } from 'src/ui/typography'
import ReactMarkdown from 'react-markdown'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => theme.paddings.main * 3}px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Notice = () => {
  const theme = useTheme()
  const { isMobile } = useDimensions()
  const iconColor = theme.colors.black.secondary

  const socialNetworks: SocialNetwork[] = [
    {
      link: 'https://vk.com/appkode',
      icon: <Icons.SocialVk color={iconColor} />,
    },
    {
      link: 'https://t.me/appkode',
      icon: <Icons.SocialTg color={iconColor} />,
    },
    // {
    //   link: 'https://www.linkedin.com/company/appkode',
    //   icon: <Icons.SocialIn color={iconColor} />,
    // },
    // {
    //   link: 'https://www.behance.net/appkode',
    //   icon: <Icons.SocialBe color={iconColor} />,
    // },
    // {
    //   link: 'https://dribbble.com/appkode',
    //   icon: <Icons.SocialDrib color={iconColor} />,
    // },
  ]

  const textMd = `


#### Подписывайтесь на наш телеграм-канал [Open KODÉ](https://t.me/appkode), и следите за анонсами.

`

  return (
    <Wrapper>
      <Markdown>
        <HBox height={40} />
        <Header
          size={isMobile ? 'h4' : 'h2'}
          color={theme.colors.brand.primary}
        >
          Осенняя стажировка уже закончилась, но мы ждем всех весной!
        </Header>
        <ReactMarkdown source={textMd} />
      </Markdown>
      <HBox height={theme.paddings.half * 1} />
      <SocialNetworks items={socialNetworks} />
    </Wrapper>
  )
}
